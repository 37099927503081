<template>
  <div>

    <div class="header-buttons-wrapper">
      <actions-buttons class="header-buttons-wrapper-left" :btns="filteredOperations" />
      <div class="header-buttons-wrapper-right">
        <div class="arrows-wrapper" v-if="visibleBalances.length > 0">
          <button class="wallet-list-prev"><icon name="ic_down"/></button>
          <button class="wallet-list-next"><icon name="ic_up"/></button>
        </div>
        <wallet-dropdown-menu />
      </div>
    </div>

    <balance-list />

    <div class="delimiter"/>
  </div>
</template>

<script>
import PaymentCreateModal from "@/components/modals/PaymentCreateModal";
import ConversionForm from "@/components/modals/ConversionForm";
import TopUp from "@/components/modals/TopUp";
import CreateCard from "@/components/wallet/cards/modals/CreateCard";
import WalletDropdownMenu from "@/components/wallet/parts/WalletDropdownMenu";
import {mapGetters, mapState} from 'vuex';
import Icon from "@/libs/Icon";
import BalanceList from "@/components/dashboard/BalanceList";
import ActionsButtons from "@/components/wallet/parts/ActionsButtons.vue";
import {FEATURES} from "@/store/modules/features";

export default {
  name: 'WalletPaymentsHeader',
  components: {
    BalanceList,
    WalletDropdownMenu,
    Icon,
    ActionsButtons
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapGetters({
      permissions: 'permissions',
      visibleBalances: 'getActiveVisibleBalances',
      hasTopUpFeature: 'hasTopUpFeature',
      canSupport: 'canSupport',
    }),
    ...mapState({
      user: state => state.user.data,
      balances: state => state.balance.items,
    }),

    canOrderCard () {
      return this.permissions.canCardCreate && this.canSupport(FEATURES.CARD)
    },

    canTopUp () {
      return this.permissions.canWalletTopUp && this.hasTopUpFeature
    },

    /**
     * Check if user have many currencies
     * for conversions
     */
    canMakeConversions () {
      return this.permissions.canCurrencyExchangeCreate
    },

    canPaymentCreate () {
      return this.permissions.canPaymentCreate || this.permissions.canPaymentDraftCreate
    },

    operations () {
      return [
        {
          label: "Make payment",
          class: "make-payment",
          isVisible: false,
          disabled: false,
          show: this.canPaymentCreate,
          action: this.makeNewPayment
        },
        {
          label: "Order a card",
          class: "order-card",
          isVisible: false,
          disabled: false,
          show: this.canOrderCard,
          action: this.orderCard
        },
        {
          label: "Currency exchange",
          class: "currency-exchange",
          isVisible: false,
          disabled: false,
          show: this.canMakeConversions,
          action: this.makeConversion,
        },
        {
          label: "Top up",
          class: "topup-card",
          isVisible: false,
          disabled: false,
          show: this.canTopUp,
          action: this.makeTopUp,
        }
      ]
    },
    filteredOperations () {
      return this.operations.filter(i => i.show === true)
    },
  },
  methods: {
    orderCard () {
      this.$modal.show(CreateCard)
    },
    makeNewPayment () {
      this.$modal.show(PaymentCreateModal)
    },
    makeConversion () {
      this.$modal.show(ConversionForm)
    },
    makeTopUp () {
      this.$modal.show(TopUp)
    },
  }
}
</script>

<style lang="less">

.delimiter {
  background-color: rgba(255, 255, 255, 0.09);
  width: 100%;
  height: 1px;
}

.header-buttons-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  padding: 30px;
  border-bottom: none;

  &-right {
    display: flex;
    gap: 15px;

    .arrows-wrapper {
      display: flex;
      gap: 15px;

      button {
        min-width: 48px;
      }
    }
  }

  &-left {
    width: 100%;
  }

  .arrows-wrapper {
    @media all and (max-width: 768px) {
      display: none;
    }
  }

  @media all and (max-width: 768px) {
    padding: 30px 20px 20px;
  }
}

</style>
