<template>
  <div id="dashboard" class="main-content">
    <wallet-payments-header />
    <wallet-payments :key="'dashboard'"/>
  </div>
</template>

<script>
import WalletPayments from "@/components/wallet/payments/WalletPayments";
import WalletPaymentsHeader from "@/components/wallet/payments/WalletPaymentsHeader";

export default {
  components: {
    WalletPaymentsHeader,
    WalletPayments,
  }
}
</script>