<template>
  <div class="wallet-list" v-if="items.length > 0">
    <transition name="fade">
      <div v-if="!loading">
        <swiper class="swiper" :options="swiperOptions">
          <swiper-slide v-for="item in items" :key="item.id">
            <router-link :to="{name: 'WalletView', params: {id: item.id}}" :key="item.id" custom v-slot="{ navigate }">
              <div class="wallet-list-item" @click.stop="navigate">
                <div class="wallet-list-item-flag">
                  <flag :name="item.currency.id" />
                </div>
                <div class="wallet-list-item-name">{{ item.currency.name }} (<span class="notranslate">{{ item.currency.id }}</span>)</div>
                <div class="wallet-list-item-balance">{{ item.currency.symbol }} {{ item.amount | numeral('0,0.00') }}</div>
                <div class="wallet-list-item-iban notranslate">{{ item.title }}</div>
                <icon v-if="item.isCardWallet" name="p_card" class="wallet-list-item-card" />
              </div>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Icon from "@/libs/Icon";
import Flag from "@/libs/Flag";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import 'swiper/css/swiper.css'

export default {
  name: 'BalanceList',
  components: {
    Icon,
    Flag,
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 20,
        navigation: {
          nextEl: '.wallet-list-next',
          prevEl: '.wallet-list-prev',
        },
        breakpoints: {
          1150: { slidesPerView: 3 },
          768:  { slidesPerView: 2 },
          481:  { slidesPerView: 2 },
        }
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      items: 'getActiveVisibleBalances',
    })
  },
  watch: {
    items () {
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 0)
    }
  },
}
</script>

<style lang="less">

.wallet-list {
  padding: 0 30px 30px;
  height: 146px;

  &-flex {
    display: flex;
    flex-direction: row;
    grid-column-gap: 20px;
    position: relative;
    overflow: hidden;
    flex-basis: fit-content;
  }

  &-content > a {
    transition: none;
  }

  &-item {
    background: #262629;
    padding: 20px 60px 20px 20px;
    font-family: 'Graphik';
    position: relative;
    cursor: pointer;
    border-radius: 2px;

    &-wrap {
      overflow: hidden;
    }

    &-balance,
    &-iban,
    &-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-name {
      font-size: 12px;
      padding-left: 35px;
    }

    &-iban {
      font-size: 12px;
      opacity: 0.6;
      margin-top: 6px;
    }

    &-balance {
      color: #FFFFFF;

      font-size: 24px;
      font-weight: 600;
      letter-spacing: 0;

      margin-top: 16px;
      margin-bottom: 12px;
    }

    &-flag {
      position: absolute;
      left: 20px;
      top: 13px;
    }

    &-card {
      position: absolute;
      right: 15px;
      top: 15px;

      fill: #fff;
      opacity: .7;
    }
  }

  &-next,
  &-prev {
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    font-weight: 500;

    border-radius: 2px;
    background: #262629;

    cursor: pointer;
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(90deg);

      fill: #fff;
      opacity: .6;
    }

    &:hover {
      background-color: #FFFFFF;

      svg {
        fill: #19191C;
        opacity: 1;
      }
    }

    &.swiper-button-disabled {
      cursor: not-allowed;
      background: #262629;

      svg {
        fill: #fff;
        opacity: .2;
      }
    }
  }

  @media all and (max-width: 768px) {
    padding: 0 20px 20px;
  }
}
</style>
